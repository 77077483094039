import { FC } from "react";
import Link from "next/link";
import CardAdviceBlogDate from "../cardAdviceBlogDate";
import CardAdviceBlogImage from "../cardAdviceBlogImage";
import CardAdviceBlogTitle from "../cardAdviceBlogTitle";

import { Article } from "../../backendAPI/DatingAdvice";

type Props = {
  advice: Article;
};

const CardAdviceBlog: FC<Props> = ({ advice }) => {
  return (
    <Link
      href={`/dating-advice/${
        advice && advice.url ? advice.url.replace("/Article/", "") : "#"
      }`}
    >
      <a>
        <div className="CardAdviceBlog rounded-t-2xl rounded-b shadow h-full w-full flex flex-col">
          <CardAdviceBlogImage img={advice.mainImage} />
          <div className="pt-4 pb-2.5 px-4">
            <CardAdviceBlogDate date={advice.postedDate} />
          </div>
          <div className="px-4 pb-4">
            <CardAdviceBlogTitle title={advice.title} />
          </div>
        </div>
      </a>
    </Link>
  );
};

export default CardAdviceBlog;
