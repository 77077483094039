import { FC, useContext, useEffect, useRef, useState } from "react";
import CardProfilePictureSmall from "../cardProfilePictureSmall";

import useSearch from "../../hooks/useSearch";
import useUser from "../../hooks/useUser";
import Link from "next/link";
import { TranslationContext } from "../../context/TranslationContext";

const CardWhoWantsToMeetYou: FC = () => {
  const { i } = useContext(TranslationContext);
  const targetRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    function handleResize() {
      if (!targetRef || !targetRef.current) return;

      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    setTimeout(() => {
      handleResize();
    }, 350);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { members, isLoading } = useSearch();

  const [maxNumberOfMatchesToShow, setMaxNumberOfMatchesToShow] = useState(7);

  useEffect(() => {
    if (!dimensions || !members) return;

    const maxNumber = getMaxNumberOfMatchesToShowBasedOnWindowWidth(
      dimensions.width
    );

    setMaxNumberOfMatchesToShow(maxNumber);
  }, [dimensions, members]);

  if (members.length == 0 && !isLoading) {
    return (
      <div className="CardWhoWantsToMeetYou md:pr-6 md:pb-0 pb-4 w-full md:min-w-[360px] md:max-w-[1000px]">
        <div className="bg-gradient-to-r from-custom-gradient-orange to-custom-red rounded-2xl h-full pt-2.5 md:pt-5 pb-5 px-3.5 md:px-7">
          <p className="text-white text-lg pb-4">
            {i(
              "New members are signing up every day! Until then, broaden your search and let yourself be surprised!"
            )}
          </p>
          <Link href="/search" passHref>
            <button className="text-white border-2 rounded-md px-5 py-3">
              {i("Search")}
            </button>
          </Link>
        </div>
      </div>
    );
  }
  return (
    <div className="CardWhoWantsToMeetYou md:pr-6 md:pb-0 pb-4 w-full md:min-w-[360px] md:max-w-[1000px]">
      <div className="bg-gradient-to-r from-custom-gradient-orange to-custom-red rounded-2xl h-full pt-2.5 md:pt-5 pb-5 px-3.5 md:px-7 flex flex-col">
        <p
          className="text-white text-lg pb-4"
          data-testid="mactching-your-criteria-text"
        >
          {i("May match your criteria")}
        </p>
        <div ref={targetRef} className="w-full flex items-center flex-1">
          <CardProfilePictureSmall
            members={members}
            maxNumberOfMatchesToShow={maxNumberOfMatchesToShow}
          />
        </div>
      </div>
    </div>
  );
};

export default CardWhoWantsToMeetYou;

function getMaxNumberOfMatchesToShowBasedOnWindowWidth(
  windowWidth: number
): number {
  return parseInt((windowWidth / 75).toFixed(0));
}

export { getMaxNumberOfMatchesToShowBasedOnWindowWidth };
