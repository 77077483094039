import React, { FC } from "react";
import classNames from "../../utils/twClassNames";

type Props = {
  direction: "right" | "left";
  onClick: () => void;
  testid?: string;
};

const ArrowSeeMore: FC<Props> = ({ direction, onClick, testid }) => {
  return (
    <button
      data-testid={testid}
      onClick={onClick}
      className={classNames(
        "ArrowSeeMore w-[42px] h-[80px] lg:h-[108px] bg-white bg-opacity-50 grid place-items-center",
        direction === "right" ? "rounded-l-[20px]" : "rounded-r-[20px]"
      )}
    >
      <ArrowImage direction={direction} />
    </button>
  );
};

export function ArrowImage({ direction }: { direction: "right" | "left" }) {
  switch (direction) {
    case "right":
      return (
        <img src="/app/assets/Icons/rightArrow.svg" alt="Right arrow icon" />
      );
    case "left":
      return (
        <img
          src="/app/assets/Icons/rightArrow.svg"
          className="rotate-180"
          alt="Left arrow icon"
        />
      );
  }
}

export default ArrowSeeMore;
