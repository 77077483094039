import React, { FC, useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import classNames from "../../utils/twClassNames";
import CardMyProfilePictureSmall from "../cardMyProfilePictureSmall";
import CardProfilePictureFade from "../cardProfilePictureFade";
import { TranslationContext } from "../../context/TranslationContext";
import api from "../../backendAPI";

type Props = {
  userProfilePic: string;
};

const CardPerfectMatch: FC<Props> = ({ userProfilePic }) => {
  const { i } = useContext(TranslationContext);
  const [matches, setMatches] = useState<any>([]);

  const router = useRouter();

  useEffect(() => {
    api.dateMe
      .getDateMePages({ pageIndex: 1, pageSize: 3, page: "perfect_match" })
      .then((res) =>
        setMatches(
          res?.data?.list?.map((item: any) => {
            return {
              profilePicURL: item.mainImage,
              id: item.id,
              name: item.prenom,
            };
          })
        )
      );
  }, []);

  return (
    <div
      onClick={() => router.push("/date-me")}
      className="CardPerfectMatch cursor-pointer bg-gradient-to-r from-custom-gradient-blue to-custom-gradient-purple pt-2.5 md:pt-5 px-3.5 md:px-7 rounded-2xl pb-5 w-full md:min-w-[340px] md:max-w-[380px] lex flex-col"
    >
      <p className="text-white text-lg pb-4" data-testid="perfect-match-text">
        {i("Lets find a perfect match for you")}
      </p>
      <div
        className={classNames(
          "flex items-center justify-center relative",
          matches?.length < 3 ? `pr-${matches?.length * 5}` : "pr-10"
        )}
      >
        <CardMyProfilePictureSmall userProfilePic={userProfilePic} />

        <img
          src="/app/assets/logocolored.png"
          className="h-12 absolute z-40"
          alt="Your profile picture"
        />

        {matches?.length ? (
          <div className="w-[85px]">
            <CardProfilePictureFade matches={matches} />
          </div>
        ) : (
          <div className="CardProfilePictureFade w-[85px]">
            {
              <button className="h-[85px] relative ml-2 w-[85px] flex items-center border-[3px] rounded-[24px] overflow-hidden">
                <img
                  src="/app/assets/blueSearchIcon.svg"
                  alt="search icon"
                  className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] z-40 w-6 h-6"
                />
                <img
                  src="/app/assets/blurPerfectMatch.png"
                  className="h-[85px] w-[85px] absolute z-30 border-white border-[3px] object-cover blur-sm"
                  alt={" profile picture"}
                />
              </button>
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default CardPerfectMatch;
