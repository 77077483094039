import { FC } from "react";

type Props = {
  userProfilePic?: string;
};

const CardMyProfilePictureSmall: FC<Props> = ({ userProfilePic }) => (
  <div className="CardMyProfilePictureSmall">
    <div className="h-[85px] w-[85px] mr-2">
      <img
        src={userProfilePic || placeholderPictureSrc}
        className=" rounded-3xl border-white w-full h-full object-cover border-[3px]"
        alt="Your profile picture"
      />
    </div>
  </div>
);

export const placeholderPictureSrc = "/assets/AucuneImageHomme.gif";

export default CardMyProfilePictureSmall;
