import Link from "next/link";
import React, { FC, useEffect, useState } from "react";
import { Member } from "../../backendProxies";
import classNames from "../../utils/twClassNames";
import CardSeeMore from "../cardSeeMore";
import OpenProfileLink from "../openProfileLink/OpenProfileLink";

type Match = {
  profilePicURL: string;
  id: string;
  name: string;
  userName: string;
};

type Props = {
  maxNumberOfMatchesToShow: number;
  members: Member[];
};

const CardProfilePictureSmall: FC<Props> = ({
  maxNumberOfMatchesToShow,
  members,
}) => {
  const [matches, setMatches] = useState<Match[]>([]);

  useEffect(() => {
    if (!members || members.length === 0) return;

    setMatches(
      members
        .filter((member) => member.info)
        .map((member) => {
          return {
            profilePicURL: member.info.mainImage,
            id: member.info.id,
            name: member.info.prenom,
            userName: member.info.userName,
          };
        })
    );
  }, [members]);

  return (
    <div className={"CardProfilePictureSmall flex relative w-full"}>
      <div
        className={classNames(
          "flex flex-row w-full pr-5 ",
          matches.length < maxNumberOfMatchesToShow
            ? "justify-start"
            : "justify-between"
        )}
      >
        {matches.length > 0 ? (
          matches?.map((match, index) => {
            const shouldRenderMatchCard = () => {
              return index < maxNumberOfMatchesToShow;
            };

            if (shouldRenderMatchCard()) {
              return (
                <MatchCard
                  matches={matches}
                  index={index}
                  maxNumberOfMatchesToShow={maxNumberOfMatchesToShow}
                  match={match}
                  key={`${match.userName}-${index}`}
                />
              );
            }
          })
        ) : (
          <div className="flex w-full">
            {Array(9)
              .fill({})
              .map((match, index) => {
                const shouldRenderMatchCard = () => {
                  return index < maxNumberOfMatchesToShow;
                };

                if (shouldRenderMatchCard()) {
                  return (
                    <div
                      className="rounded-lg overflow-hidden "
                      key={`temp-match-${index}`}
                    >
                      <MatchCard
                        matches={matches}
                        index={index}
                        maxNumberOfMatchesToShow={maxNumberOfMatchesToShow}
                        match={match}
                      />
                    </div>
                  );
                }
              })}
          </div>
        )}
      </div>
    </div>
  );
};

type MatchCardProps = {
  matches: Match[];
  index: number;
  maxNumberOfMatchesToShow: number;
  match: Match;
};

const MatchCard: FC<MatchCardProps> = ({
  matches,
  index,
  maxNumberOfMatchesToShow,
  match,
}) => {
  const shouldShowCardSeeMore = () =>
    index == maxNumberOfMatchesToShow - 1 &&
    matches.length > maxNumberOfMatchesToShow;

  return (
    <div className="flex" key={index}>
      {shouldShowCardSeeMore() ? (
        <CardSeeMore matches={matches} />
      ) : (
        <MatchedCard match={match} />
      )}
    </div>
  );
};

type CardMatchProps = {
  match: Match;
};

const MatchedCard: FC<CardMatchProps> = ({ match }) => {
  return (
    <OpenProfileLink username={match.userName}>
      <div className="w-[65px] h-[85px] flex justify-start cursor-pointer">
        <div
          className={classNames(
            match.profilePicURL ? "border-[3px] border-white" : "",
            "rounded-3xl h-[85px] absolute w-[85px] overflow-hidden "
          )}
        >
          {match.profilePicURL ? (
            <img
              src={match.profilePicURL}
              alt={match.name + " profile picture"}
            />
          ) : (
            <div className="bg-gradient w-full h-full bg-gradient-to-t from-gray-500 to-white/80 wave-load rounded-3xl"></div>
          )}
        </div>
      </div>
    </OpenProfileLink>
  );
};

export default CardProfilePictureSmall;
