import React, { FC, useEffect, useState } from "react";
import CardPerfectMatch from "../cardPerfectMatch";
import CardWhoWantsToMeetYou from "../cardWhoWantsToMeetYou";
import PageHeader from "../pageHeader";

import useUser from "../../hooks/useUser";

import useTranslation from "../../context/TranslationContext";
import ButtonBlueBGWhite from "../buttonBlueBGWhite";
import api from "../../backendAPI";
import StyledPopup from "../styledPopup";
import useAuth from "../../hooks/useAuth";
import classNames from "../../utils/twClassNames";
import reactStringReplace from "react-string-replace";
import ButtonBGBlue from "../buttonBGBlue";
import StyledTextField from "../styledTextField";
import { useRouter } from "next/router";

declare global {
  interface Window {
    a2a?: {
      init_all: () => void;
    };
  }
}

const WelcomeBackSection: FC = () => {
  const { userData } = useUser();
  const router = useRouter();

  const { i } = useTranslation();

  return (
    <div className="WelcomeBackSection">
      <div className="pb-2.5 md:pb-4">
        <PageHeader
          title={`${i("Welcome")} ${
            userData && userData.firstname ? userData.firstname : ""
          }!`}
        />
      </div>
      <div className="flex flex-col md:flex-row">
        <CardWhoWantsToMeetYou />
        <WelcomeBackCTA forceSharePopup={+router.query["share"] === 1} />
      </div>
    </div>
  );
};

export default WelcomeBackSection;

type ServerAction = {
  label: string;
  endpoint: string;
};

export type HomeCta =
  | { type: "dateMe" }
  | {
      type: "share";
      content: {
        icon: string;
        title: string;
        description: string;
        actionCancel: ServerAction;
        action: ServerAction;
        popup: {
          shareUrl: string;
          contentHTML: string;
          actionDone: ServerAction;
          successMessage: string;
          nextStep: {
            title: string;
            subTitle: string;
            "data-field-name-label": string;
            "data-field-network-name-label": string;
          };
        };
      };
    };

/**
 * Blue Container on home Page
 */
function WelcomeBackCTA({
  forceSharePopup: defaultForceSharePopup = false,
}: {
  forceSharePopup?: boolean;
}) {
  const [forceSharePopup, setForceSharePopup] = useState(
    defaultForceSharePopup
  );
  const [openPopup, setOpenPopup] = useState(false);
  const [shared, setShared] = useState(false);
  const [step, setStep] = useState(0);
  const [name, setName] = useState("");
  const [hasError, setHasError] = useState(false);
  const [networkName, setNetworkName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { userData, homeCta } = useUser();
  const [data, setData] = useState<HomeCta | null>(
    forceSharePopup ? null : homeCta
  );
  const { getLoginInfo } = useAuth();
  const { i } = useTranslation();

  const handleCancel = async () => {
    api.general.post("/share/not-now", {}).then(() => getLoginInfo());
  };

  const handlePopup = () => {
    setOpenPopup(true);
  };
  const handleShare = async () => {
    if (!name || !networkName) {
      return setHasError(true);
    }
    setIsLoading(true);
    api.general
      .post("/share", {
        name,
        networkName,
      })
      .then(() => {
        setShared(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onClose = () => {
    setOpenPopup(false);
    setData(homeCta);
    getLoginInfo();
  };

  useEffect(() => {
    // Remove ?share=1 from the URL
    const query = new URLSearchParams(window.location.search);
    if (query.has("share")) {
      query.delete("share");
      const newUrl = `${window.location.pathname}?${query.toString()}`;
      window.history.replaceState(
        null,
        "",
        newUrl.endsWith("?") ? newUrl.slice(0, -1) : newUrl
      );
    }
  }, [forceSharePopup]);

  useEffect(() => {
    if (forceSharePopup) {
      api.general
        .get("/share", {})
        .then((res) => setData(res?.data["blueContainerContent"] ?? null));
    }
  }, [forceSharePopup]);

  useEffect(() => {
    if (!forceSharePopup) {
      setData(homeCta);
    }
  }, [homeCta]);

  useEffect(() => {
    if (defaultForceSharePopup) {
      setForceSharePopup(true);
    }
  }, [defaultForceSharePopup]);

  useEffect(() => {
    if (data && forceSharePopup) {
      setOpenPopup(true);
      setForceSharePopup(false);
    }
  }, [data]);

  useEffect(() => {
    if (!openPopup) return;

    if (!document.getElementById("addtoany")) {
      const script = document.createElement("script");
      script.src = "https://static.addtoany.com/menu/page.js";
      script.id = "addtoany";
      script.async = true;
      script.onload = () => {
        window.a2a?.init_all();
      };
      document.body.appendChild(script);
    }
    return () => {
      document.getElementById("addtoany")?.remove();
    };
  }, [openPopup]);

  useEffect(() => {
    setHasError(false);
    setName("");
    setNetworkName("");
  }, [step]);
  if (!data) return null;
  if (data.type === "dateMe")
    return (
      <CardPerfectMatch
        userProfilePic={
          userData && userData.mainImage
            ? userData.mainImage
            : "https://cdn.celibatairesduweb.com/img/Vignettes/AucuneImageHomme.gif"
        }
      />
    );

  return (
    <div
      className={classNames(
        "CardPerfectMatch bg-gradient-to-r from-custom-gradient-blue to-custom-gradient-purple pt-2.5 md:pt-5 px-3.5 md:px-7 rounded-2xl pb-5 w-full md:min-w-[340px] flex flex-col gap-1",
        data.type === "share" ? "md:max-w-[480px]" : "md:max-w-[380px]"
      )}
    >
      <div className="flex gap-6 items-center justify-center">
        <img
          src={data.content.icon}
          alt="cta-icon"
          className="object-fit h-12 w-12"
        />
        <p
          className="text-white font-medium text-lg whitespace-pre"
          data-testid="perfect-match-text"
        >
          {data.content.title.replace("\\n", "\n")}
        </p>
      </div>

      <p
        className="text-white text-center whitespace-pre-wrap"
        data-testid="perfect-match-text"
      >
        {reactStringReplace(
          data.content.description.replace("\\n", "\n"),
          "{action}",
          () => (
            <button
              className="text-white font-medium underline"
              onClick={handlePopup}
            >
              {data.content.action.label}
            </button>
          )
        )}
      </p>
      <div className="flex flex-col items-center gap-2">
        <button className="text-white/70" onClick={handleCancel}>
          {data.content.actionCancel.label}
        </button>
      </div>
      <StyledPopup
        key={"blue-popup"}
        open={openPopup}
        onClick={(open) => {
          if (!open) onClose();
        }}
      >
        {shared ? (
          <div className="max-w-[450px] flex flex-col justify-center items-center gap-8 py-6">
            <p
              className="text-xl text-center"
              dangerouslySetInnerHTML={{
                __html: data.content.popup.successMessage,
              }}
            />
            <div>
              <svg
                className="checkmark"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                <circle
                  className="checkmark__circle"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />
                <path
                  className="checkmark__check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
            </div>
          </div>
        ) : step === 0 ? (
          <div className="p-4">
            <div
              dangerouslySetInnerHTML={{
                __html: data.content.popup.contentHTML,
              }}
            />
            <div className="flex gap-4">
              <ButtonBlueBGWhite
                onClick={() => setStep(1)}
                innerText={data.content.popup.actionDone.label}
              />
            </div>
          </div>
        ) : (
          <div className="p-4">
            <div className="sm:px-24">
              <div className="text-left">
                <div className="flex flex-col sm:flex-row gap-6 items-center justify-center">
                  <img
                    src="https://www.celibatairesduweb.com/img/divers/Share-icon_100.png"
                    alt="cta-icon"
                    className="object-fit h-12 w-12"
                  />
                </div>

                <p className="my-4 text-left font-medium">
                  <b>{data.content.popup.nextStep.subTitle}</b>
                </p>
                <div className="mb-4">
                  <p className="mb-2">
                    {data.content.popup.nextStep["data-field-name-label"]}
                  </p>
                  <StyledTextField
                    className={`h-10 ${
                      hasError && !name ? "border-[#CC0123] border-2" : ""
                    }`}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                <div className="mb-4">
                  <p className="mb-2">
                    {
                      data.content.popup.nextStep[
                        "data-field-network-name-label"
                      ]
                    }
                  </p>
                  <StyledTextField
                    className={`h-10 ${
                      hasError && !networkName
                        ? "border-[#CC0123] border-2"
                        : ""
                    }`}
                    value={networkName}
                    onChange={(e) => setNetworkName(e.target.value)}
                  />
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: data.content.popup.nextStep.subtitle_2,
                  }}
                  className="my-2 text-left mb-8"
                ></p>
              </div>
            </div>
            <div className="flex gap-4 justify-center">
              <ButtonBlueBGWhite
                className="!w-fit"
                disabled={isLoading}
                onClick={() => setStep(0)}
                innerText={i("Cancel")}
              />
              <ButtonBGBlue
                className="!w-fit"
                disabled={(hasError && (!name || !networkName)) || isLoading}
                onClick={handleShare}
                innerText={
                  isLoading ? (
                    <div className="LoadingSpinner w-20 justify-center flex">
                      <div className="flex items-center justify-center w-4">
                        <div className="animate-spin flex">
                          <img src="/app/assets/spinner.png" alt="spinner" />
                        </div>
                      </div>
                    </div>
                  ) : (
                    i("Confirm")
                  )
                }
              />
            </div>
          </div>
        )}
      </StyledPopup>
    </div>
  );
}
