import React, { FC, useState, useEffect } from "react";
import SectionHeader from "../sectionHeader";
import CardAdviceBlog from "../cardAdviceBlog";
import LoadingSpinner from "../loadingSpinner";
import { Advice, AdviceList } from "../../backendProxies";
import useTranslation from "../../context/TranslationContext";

const HomeDatingAdviceSection: FC = () => {
  const { i, locale } = useTranslation();

  useEffect(() => {
    const datingAdvices = new AdviceList("blogs", {
      pageSize: 2,
      locale,
    });

    datingAdvices.getElements().then(([data, hasMore]) => {
      setIsLoading(false);
      setBlogPosts(data);
    });
  }, [locale]);

  const [isLoading, setIsLoading] = useState(true);
  const [blogPosts, setBlogPosts] = useState<Advice[]>([]);

  return (
    <div className="HomeDatingAdviceSection">
      <div className="mb-5">
        <SectionHeader
          title={i("Dating advice")}
          sectionTestID="dating-advice-header"
        />
      </div>
      <div className="flex flex-col md:flex-row md:space-x-6 mb-16 h-full">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {blogPosts.map((blogPost) => (
              <div key={blogPost.id} className="mt-5 md:mt-0 w-full h-full">
                <CardAdviceBlog advice={blogPost} />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default HomeDatingAdviceSection;
