import React, { FC } from "react";

type Match = {
  profilePicURL: string;
  id: string;
  name: string;
};

type Props = {
  matches: Match[];
};

const CardProfilePictureFade: FC<Props> = ({ matches }) => {
  return (
    <div className="CardProfilePictureFade w-[85px]">
      {matches && (
        <div className="h-[85px] relative ml-2 w-[85px] flex items-center">
          {matches[0] && (
            <img
              data-testid={matches[0].profilePicURL}
              src={matches[0].profilePicURL}
              className="h-[85px] absolute z-30 border-[3px] rounded-3xl border-white object-cover"
              alt={matches[0].name + " profile picture"}
            />
          )}
          {matches[1] && (
            <img
              data-testid={matches[1].profilePicURL}
              src={matches[1].profilePicURL}
              className="h-[74px] absolute left-12 z-20 border-[3px] rounded-3xl border-white object-cover"
              alt={matches[1].name + " profile picture"}
            />
          )}
          {matches[2] && (
            <img
              data-testid={matches[2].profilePicURL}
              src={matches[2].profilePicURL}
              className="h-[61px] w-[61px] absolute left-20 z-10 border-[3px] rounded-3xl border-white object-cover"
              alt={matches[2].name + " profile picture"}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CardProfilePictureFade;
