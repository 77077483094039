import Head from "next/head";

import PageContainer from "../components/pageContainer";
import WelcomeBackSection from "../components/welcomeBackSection";
import BodyAdContainerFlexible from "../components/bodyAdContainerFlexible";
import AuthGuard from "../components/authGuard";

import MemberSection from "../components/memberSection";

import HomeDatingAdviceSection from "../components/homeDatingAdviceSection";

import useUser from "../hooks/useUser";
import { useContext, useEffect, useState } from "react";

import useAuth from "../hooks/useAuth";

import { useRouter } from "next/router";

import Popup from "../components/popup";
import ButtonBGBlue from "../components/buttonBGBlue";

import { TranslationContext } from "../context/TranslationContext";
import useRegistration from "../hooks/useRegistration";
import useSearch from "../hooks/useSearch";
import AdWrapper from "../components/AdWrapper";
import useOnlineNow from "../hooks/useOnlineNow";
import StyledPopup from "../components/styledPopup";
import SugarDaddyAd from "../components/SugarDaddyAd";

const Home = () => {
  const router = useRouter();

  const { i, lang } = useContext(TranslationContext);

  const [openProfileReactivatedPopup, setOpenProfileReactivatedPopup] =
    useState(false);

  const { userData, clearWarnings } = useUser();

  const { members, fetchResults } = useSearch();

  const { preferencesLoaded } = useOnlineNow();

  const { justFinishedRegistration, setJustFinishedRegistration } =
    useRegistration();

  const { accessToken } = useAuth();

  // useEffect(() => {
  //   // just finished registration, auto login to get the right information
  //   if (justFinishedRegistration) {
  //     setJustFinishedRegistration(false);

  //     getLoginInfo();
  //   }
  // }, []);

  useEffect(() => {
    if (members && members.length !== 0) return;

    if (
      !userData ||
      !userData.searchDefaultParams ||
      !userData.searchDefaultParams.selectedPlaceName
    )
      return;

    fetchResults(false);
  }, [userData, justFinishedRegistration]);

  useEffect(() => {
    if (!userData || !userData.warnings) return;

    if (userData.warnings.includes("INFOS_PROFILE_REACTIVATED")) {
      clearWarnings();
      setOpenProfileReactivatedPopup(true);
      return;
    }
  }, []);

  return (
    <AuthGuard>
      <div className="">
        <Head>
          <title>{i("Home")}</title>

          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1"
          />
          <meta name="robots" content="noindex" />
        </Head>
        <main>
          <PageContainer pagesWithNotification={[]} currentPage="Home">
            <div className="w-full">
              <WelcomeBackSection />

              <div className="w-full mt-7">
                {accessToken && preferencesLoaded && (
                  <MemberSection
                    title={i("New members")}
                    endpoint="newMembers"
                    query={{ pageSize: 20 }}
                    seeAllUrl="/newMembers"
                    sectionTestID="new-member-header"
                  />
                )}
              </div>
              <AdWrapper>
                <SugarDaddyAd />
                <div className="w-full h-[228px] pt-6">
                  <BodyAdContainerFlexible googleid="1314114618" />
                </div>
              </AdWrapper>

              <div className="w-full mt-7">
                {accessToken && preferencesLoaded && (
                  <MemberSection
                    sectionTestID="online-now-header"
                    title={i("See who's online now")}
                    seeAllUrl="/online-now"
                    endpoint="online"
                    query={{
                      pageSize: 20,
                      type: "read",
                      ageMin: userData.searchDefaultParams?.ageMin || 30,
                      ageMax: userData.searchDefaultParams?.ageMax || 45,
                      distance: 550,
                      genderId:
                        userData.searchDefaultParams?.sexeRechercheId || 1,
                    }}
                  />
                )}
              </div>
              <div className="w-full mt-7">
                {accessToken && preferencesLoaded && (
                  <MemberSection
                    seeAllUrl="/history"
                    sectionTestID="my-history-header"
                    title={i("My history")}
                    endpoint="history"
                    removable={true}
                    query={{
                      pageSize: 20,
                    }}
                  />
                )}
              </div>
              <AdWrapper>
                <div className="w-full h-[228px] pt-6 pb-7">
                  <BodyAdContainerFlexible googleid="6343080012" />
                </div>
              </AdWrapper>

              <HomeDatingAdviceSection />
            </div>
          </PageContainer>
        </main>
        <StyledPopup
          open={openProfileReactivatedPopup}
          onClick={setOpenProfileReactivatedPopup}
          hideCloseButton
        >
          <div className="items-end w-full flex flex-col">
            <button onClick={() => setOpenProfileReactivatedPopup(false)}>
              <img src="/app/assets/deleteIcon.svg" alt="cancel button" />
            </button>
            <div className="w-full h-full pb-5 flex items-center flex-col justify-center">
              <div className="w-full flex justify-center">
                <img
                  src="/app/assets/vidamora-logo.svg"
                  alt="vidamora camera icon"
                  className="w-20 object-cover"
                />
              </div>
              <div>
                <p className="text-custom-black text-xl font-semibold w-full text-center mb-2 mt-6">
                  {i("We are happy to see you again!")}
                </p>
                <p
                  data-testid="messenger-video-chat-coming-soon-text"
                  className="text-custom-black text-lg w-full text-center"
                >
                  {i(
                    "Your profile is now reactivated. We suggest you to verify and update your profile information."
                  )}
                </p>
                <div className="flex justify-center mt-8">
                  <ButtonBGBlue
                    innerText={i("Modify my profile")}
                    className="w-full xs:w-[200px] h-[50px]"
                    onClick={() => {
                      router.push("/profile/my-profile");
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </StyledPopup>
      </div>
    </AuthGuard>
  );
};

export default Home;
