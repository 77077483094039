import { useSelector } from "react-redux";

import useActions from "../redux/useActions";

import { RootState } from "../redux/store";

import {
  moveBackward,
  moveForward,
  setQuestionAnswer,
  State,
  registrationSlice,
  RegistrationAnswers,
  setIsDone,
  setSelectedCityName,
  setSelectedCountryName,
  setJustFinishedRegistration,
  setIsFading,
  setLastIndex,
} from "../redux/slices/registrationSlice";

type UseRegistration = () => State & typeof registrationSlice.actions;

const useRegistration: UseRegistration = () => {
  const currentIndex = useSelector(
    (state: RootState) => state.registration.currentIndex
  );

  const isFading = useSelector(
    (state: RootState) => state.registration.isFading
  );

  const answers = useSelector((state: RootState) => state.registration.answers);

  const isDone = useSelector((state: RootState) => state.registration.isDone);

  const justFinishedRegistration = useSelector(
    (state: RootState) => state.registration.justFinishedRegistration
  );

  const actions = useActions({
    moveBackward,
    moveForward,
    setQuestionAnswer,
    setIsDone,
    setSelectedCityName,
    setSelectedCountryName,
    setJustFinishedRegistration,
    setIsFading,
    setLastIndex,
  });

  return {
    answers,
    currentIndex,
    isDone,
    justFinishedRegistration,
    isFading,

    ...actions,
  };
};

export { setQuestionAnswer };
export type { RegistrationAnswers };

export default useRegistration;
