import React, { FC } from "react";

type Props = {
  date: string;
};

const CardAdviceBlogDate: FC<Props> = ({ date }) => {
  return (
    <div className="CardAdviceBlogDate">
      <p
        className="font-normal text-sm text-custom-black"
        data-testid="advice-blog-date"
      >
        {date}
      </p>
    </div>
  );
};

export default CardAdviceBlogDate;
