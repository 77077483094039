import Link from "next/link";
import React, {
  FC,
  useRef,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import ArrowSeeMore from "../arrowSeeMore";
import CardMemberProfile from "../cardMemberProfile";
import SectionHeader from "../sectionHeader";
import useEmblaCarousel from "embla-carousel-react";

import { Member } from "../../backendProxies";
import { TranslationContext } from "../../context/TranslationContext";

import NoMatchFound from "../noMatchFound";

import useWindowSize from "../../hooks/useWindowSize";
import ButtonBGBlue from "../buttonBGBlue";
import { useRouter } from "next/dist/client/router";

type Props = {
  title: string;
  seeAllUrl: string;
  memberProfiles: Member[];
  setShouldLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
  destination: string;
  removable?: boolean;
  hasMore: boolean;
  isLoading: boolean;
  sectionTestID: string;
};

const MemberCarousel: FC<Props> = ({
  title,
  seeAllUrl,
  memberProfiles,
  setShouldLoadMore,
  removable = false,
  isLoading,
  hasMore,
  sectionTestID,
}) => {
  const { i } = useContext(TranslationContext);

  const loadingMoreRef = useRef(false);
  const lastCurrentSnapRef = useRef(0);

  const { windowWidth } = useWindowSize();

  const [emblaRef, emblaApi] = useEmblaCarousel({
    draggable: true,
    containScroll: "trimSnaps",
    inViewThreshold: 0,
    slidesToScroll: (windowWidth && windowWidth > 768 ? 3 : 2) || 3,
  });

  useEffect(() => {
    if (emblaApi && memberProfiles.length !== 0) emblaApi.reInit();
  }, [emblaApi, memberProfiles]);

  // useEffect(() => {
  //   if (!emblaApi) return;

  //   const handleSelect = () => {
  //     const numberOfSnaps = emblaApi.scrollSnapList().length;
  //     const currentSnap = emblaApi.selectedScrollSnap();

  //     lastCurrentSnapRef.current = currentSnap;

  //     if (currentSnap >= numberOfSnaps - 3 && hasMore) {
  //       loadingMoreRef.current = true;
  //       setShouldLoadMore(true);
  //     }
  //   };

  //   emblaApi.on("select", handleSelect);

  //   const handleReInit = () => {
  //     if (memberProfiles.length === 0) return;
  //     if (!loadingMoreRef.current) return;
  //     loadingMoreRef.current = false;

  //     emblaApi.scrollTo(lastCurrentSnapRef.current - 1, true);

  //     setTimeout(() => {
  //       emblaApi.scrollTo(lastCurrentSnapRef.current + 1, false);
  //     }, 350);
  //   };

  //   emblaApi.on("reInit", handleReInit);

  //   return () => {
  //     emblaApi.off("select", handleSelect);
  //     emblaApi.off("reInit", handleReInit);
  //   };
  // }, [emblaApi]);

  const scrollPrev = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollPrev();
    }
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollNext();
    }
  }, [emblaApi]);

  if (!isLoading && memberProfiles.length === 0) {
    return <></>;
  }

  return (
    <div
      data-testid={`member-carousel-for-${title}`}
      className="w-full MemberCarousel embla relative"
    >
      <div className="w-full flex justify-between mb-5">
        <SectionHeader title={title} sectionTestID={sectionTestID} />
        <button>
          <Link href={`${seeAllUrl}`}>
            <a data-testid={`member-carousel-see-all-link-for-${seeAllUrl}`}>
              <p className="text-lg font-semibold text-custom-blue">
                {i("See all")}
              </p>
            </a>
          </Link>
        </button>
      </div>
      <div className="relative flex items-center">
        <div className="absolute -left-5 z-10">
          {
            <ArrowSeeMore
              direction="left"
              onClick={() => {
                scrollPrev();
              }}
            />
          }
        </div>
        <div className="overflow-hidden flex">
          {memberProfiles.length > 0 ? (
            <div className="embla__viewport overflow-hidden" ref={emblaRef}>
              <div className="embla__container flex">
                {memberProfiles
                  .map((member, index) => (
                    <div
                      className="embla__slide min-w-[170px] md:min-w-[250px] relative pl-4"
                      key={index}
                    >
                      <div className="embla__slide__inner">
                        <CardMemberProfile
                          member={member}
                          isRemovable={removable}
                        />
                      </div>
                    </div>
                  ))
                  .concat(
                    <div key={memberProfiles.length + 1} className="pl-4">
                      <SeeMore seeAllUrl={seeAllUrl} />
                    </div>
                  )}
              </div>
            </div>
          ) : (
            <PlaceholderWhileLoading />
          )}
        </div>
        <div className="absolute -right-5">
          {memberProfiles?.length > 4 && (
            <ArrowSeeMore
              direction="right"
              onClick={() => {
                scrollNext();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

function PlaceholderWhileLoading() {
  const placeholders = new Array(20).fill(0).map((_, index) => (
    <div key={index}>
      <SinglePlaceholder />
    </div>
  ));

  return <>{placeholders}</>;
}

function SinglePlaceholder() {
  return (
    <div className="min-w-[170px] md:min-w-[250px]">
      <div className="CardMemberProfile xs:max-w-[250px] max-w-[170px] bg-center m-auto md:h-[330px] h-[224px] rounded-2xl overflow-hidden bg-cover relative col-span-1 w-full wave-load">
        <div className="flex flex-col justify-end cursor-pointer items-end md:py-3.5 py-2.5 md:px-3.5 px-2.5 h-full w-full bg-gradient"></div>
      </div>
    </div>
  );
}

function SeeMore({ seeAllUrl }: { seeAllUrl: string }) {
  const { i } = useContext(TranslationContext);
  const router = useRouter();

  return (
    <div className="w-[218px] rounded-t-2xl rounded-b shadow">
      <div className="CardMemberProfile w-full bg-center m-auto md:h-[329px] h-[223px] rounded-2xl overflow-hidden bg-cover relative col-span-1">
        <div className="flex flex-col justify-end cursor-pointer items-end md:py-3.5 py-2.5 md:px-3.5 px-2.5 h-full w-full">
          <NoMatchFound
            description={i(
              "Click on the button below [br] to see more members.",
              { br: <br key={9997} /> }
            )}
            suggestion=" "
          />
          <ButtonBGBlue
            innerText={i("See all")}
            onClick={() => {
              router.push(seeAllUrl);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default MemberCarousel;
