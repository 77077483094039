import React, { FC } from "react";

import { useRouter } from "next/router";

type Match = {
  id: string;
  name: string;
  profilePicURL: string;
};

type Props = {
  matches: Match[];
};

const CardSeeMore: FC<Props> = ({ matches }) => {
  const firstSeeMoreMatch = matches[0];

  const router = useRouter();

  const handleClick = () => {
    router.push("/search");
  };

  return (
    <div
      className="CardSeeMore w-[65px] h-[85px] flex justify-start cursor-pointer"
      onClick={handleClick}
    >
      <div className=" w-[85px] h-[85px] rounded-3xl flex absolute z-10 overflow-hidden">
        <img
          src={firstSeeMoreMatch.profilePicURL}
          className="top-0 blur-sm absolute"
          alt={firstSeeMoreMatch.name + " profile picture"}
        />
        <div className="w-full h-full m-auto flex items-center justify-center z-10 bg-black opacity-70">
          <div className="border bg-white h-2 w-2 border-white rounded-full"></div>
          <div className="border bg-white h-2 w-2 border-white rounded-full mx-1"></div>
          <div className="border bg-white h-2 w-2 border-white rounded-full"></div>
        </div>
      </div>
    </div>
  );
};

export default CardSeeMore;
