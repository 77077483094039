import React, { FC } from "react";

type Props = {
  title: string;
};

const CardAdviceBlogTitle: FC<Props> = ({ title }) => {
  return (
    <div className="CardAdviceBlogTitle">
      <h3 className="font-semibold text-lg" data-testid="advice-blog-title">
        {title}
      </h3>
    </div>
  );
};

export default CardAdviceBlogTitle;
