import React, { FC } from "react";

type Props = {
  img: string;
};

const CardAdviceBlogImage: FC<Props> = ({ img }) => {
  return (
    <div
      className="CardAdviceBlogImage h-[216px] sm:h-[240px]"
      data-testid="advice-blog-image"
    >
      <img
        alt="Blog Image"
        src={img}
        className="h-full rounded-t-2xl w-full object-cover"
      />
    </div>
  );
};

export default CardAdviceBlogImage;
