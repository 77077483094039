import React, { FC, useEffect } from "react";
import MemberCarousel from "../memberCarousel";

import useQuery from "../../hooks/useQuery";
import { Member, MemberList } from "../../backendProxies";

type Props = {
  endpoint: "newMembers" | "online" | "history";
  query: any;
  title: string;
  initialNumberOfMemberToLoad?: number;
  seeAllUrl: string;
  removable?: boolean;
  sectionTestID: string;
};

const MemberSection: FC<Props> = ({
  query,
  endpoint,
  title,
  seeAllUrl,
  removable = false,
  sectionTestID,
}) => {
  const { results, hasMore, isLoading, setShouldLoadMore, initQuery } =
    useQuery<Member>(MemberList, endpoint, query);

  useEffect(() => {
    if (results.length > 0) return;

    initQuery();
  }, []);

  return (
    <div className="MemberSection flex space-x-4">
      {
        <MemberCarousel
          title={title}
          seeAllUrl={seeAllUrl}
          memberProfiles={results}
          setShouldLoadMore={setShouldLoadMore}
          destination={endpoint}
          removable={removable}
          hasMore={hasMore}
          isLoading={isLoading}
          sectionTestID={sectionTestID}
        />
      }

      <__TestWidget setShouldLoadMore={setShouldLoadMore} />
    </div>
  );
};

function __TestWidget({
  setShouldLoadMore,
}: {
  setShouldLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <div
      style={{ display: "none" }}
      data-testid="member-section-load-more"
      onClick={() => setShouldLoadMore(true)}
    ></div>
  );
}

export default MemberSection;
