import React, { FC } from "react";

type Props = {
  title: string;
  sectionTestID?: string;
};

const SectionHeader: FC<Props> = ({ title, sectionTestID }) => (
  <div className="SectionHeader">
    <h2
      className=" sm:text-[22px] text-xl font-semibold text-custom-black"
      data-testid={sectionTestID}
    >
      {title}
    </h2>
  </div>
);

export default SectionHeader;
